import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSales(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/xjoin', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSale(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getSale', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPurchase(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getOrder', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWmList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getWmList', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSale(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/deleteSale', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSupliers() {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Clients', {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '(type,eq,customer)',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Clients'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWarehouses() {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Warehouses', {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Warehouses'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCars() {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Cars', {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Cars'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchUsers() {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Users', {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Users'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchProducts() {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Products', {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Products'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOptions() {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Selects', {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Selects'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkForCode(ctx, code) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Products', {
            _size: 1,
            _p: 1,
            _sort: '',
            _where: `(code,like,${code})`,
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Products'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addOption(ctx, optionData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Selects', optionData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getProductsbyWm(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getProductsbyWm', {
            product_id: id
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getSubitemsByWm(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getSubitemsByWm', {
            wm_id: id
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    createSale(ctx, salesData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/createSale', salesData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getSubOptions(ctx, table) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/getAll/${table}`, {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: table
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateSale(ctx, salesData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/updateSale', salesData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addSupplier(ctx, supplierData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Clients/', supplierData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addWarehouse(ctx, warehouseData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Warehouses/', warehouseData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addCar(ctx, carData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Cars/', carData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addProduct(ctx, productData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Products/', productData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFile(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/upload/', formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  }
}
